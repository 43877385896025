import { MaybeComputedRefOrFalsy } from '@unhead/vue';

type Type = 'article' | 'website' | 'book' | 'profile';

export default function ({
  title,
  description,
  image,
  type
}: {
  title: MaybeComputedRefOrFalsy<string>;
  description: MaybeComputedRefOrFalsy<string>;
  image: MaybeComputedRefOrFalsy<string>;
  type?: MaybeComputedRefOrFalsy<Type>;
}) {
  const {
    public: { cloudflareImageProcessing: cfUrl }
  } = useRuntimeConfig();
  const { t } = useI18n();
  const imageHost = useHost(true);

  const imageRef = computed<string>(() => {
    if (typeof image === 'string') {
      return image;
    }
    if (typeof image === 'function') {
      return image();
    }
    return image;
  });

  const typeRef = computed<Type>(() => {
    if (typeof type === 'string') {
      return type;
    }
    if (typeof type === 'function') {
      return type();
    }
    return (
      type && typeof type === 'object' && 'value' in type
        ? type?.value || 'website'
        : 'website'
    ) as Type;
  });

  const twitterCard = computed(() =>
    typeRef.value === 'article' ? 'summary_large_image' : 'summary'
  );

  function fixImageUrl(value: string) {
    if (value.startsWith('/') && !value.includes(imageHost)) {
      value = `${imageHost}${value}`;
    }
    if (value && !value.startsWith('/') && !value.includes(cfUrl)) {
      return `${cfUrl}/image?w=1200&h=630&f=webp&fit=crop&image=${value}`;
    }
    return value;
  }

  const processedImage = computed(() =>
    imageRef.value ? fixImageUrl(imageRef.value) : imageRef.value
  );

  useSeoMeta({
    ...(title
      ? {
          title,
          ogTitle: title,
          twitterTitle: title
        }
      : {}),
    ...(description
      ? {
          description,
          ogDescription: description,
          twitterDescription: description
        }
      : {}),
    ...(image
      ? {
          ogImage: processedImage,
          twitterImage: processedImage
        }
      : {}),
    ogType: typeRef,
    twitterCard,
    ogSiteName: t('seo.defaultTitle')
  });
}
